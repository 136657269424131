import { Report } from '@/interfaces/Report';
import { UpdateReportMutation, UPDATE_REPORT_MUTATION } from '@/lib/reportsApi';
import { useMutation } from '@apollo/client';
import { Button, DatePicker, Form, Input, InputNumber, Modal } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { FC, useState, useEffect } from 'react';
import AccountManagerSelect from '../DataPage/FormFields/AccountManagerSelect';
import AgenciesSelect from '../DataPage/FormFields/AgencySelect';
import BillingCompanySelect from '../DataPage/FormFields/BillingCompanySelect';
import BusinessModelSelect from '../DataPage/FormFields/BusinessModelSelect';
import ClientSelectField from '../DataPage/FormFields/ClientSelectField';
import CountriesSelect from '../DataPage/FormFields/CountrySelect';
import MarketSelect from '../DataPage/FormFields/MarketSelect';
import ExchangeRateField from '../DataPage/FormFields/ExchangeRateField';
import PlannerSelect from '../DataPage/FormFields/PlannerSelect';
import ProductsSelect from '../DataPage/FormFields/ProductSelect';
import SalespersonSelect from '../DataPage/FormFields/SalespersonSelect';

type Props = {
	report: Report;
	refetch: any;
	open: boolean;
	setOpen: (newOpen: string) => void;
};

const formItemLayout = {
	labelCol: {
		xs: { span: 24 },
		sm: { span: 6 },
	},
	wrapperCol: {
		xs: { span: 24 },
		sm: { span: 19 },
	},
};

const EditCampaignModal: FC<Props> = ({
	report,
	refetch,
	open,
	setOpen,
}: Props) => {
	const [form] = Form.useForm();

	let total = 0;

	const [updateReportMutation] = useMutation<any, UpdateReportMutation>(
		UPDATE_REPORT_MUTATION
	);

	const showModal = () => {
		setOpen('none');
		handleConvertDate(
			dayjs(report.startDate, 'YYYY-MM-DD'),
			dayjs(report.endDate, 'YYYY-MM-DD')
		);
	};

	const handleOk = () => {
		setOpen('none');
	};

	const handleCancel = () => {
		setOpen('none');
	};

	if (report.platforms.length > 0) {
		total = report.platforms.reduce(
			(prev: any, current: any) => prev + current.investment,
			0
		);
	}

	const months = [
		'Jan',
		'Feb',
		'Mar',
		'Apr',
		'May',
		'Jun',
		'Jul',
		'Aug',
		'Sep',
		'Oct',
		'Nov',
		'Dec',
	];

	const convertDateToString = (startDate: Dayjs, endDate: Dayjs): string => {
		const startingMonth = startDate.month();
		const endMonth = endDate.month();

		let billingMonthsObj: { [key: string]: string } = {};

		if (endDate.year() === startDate.year()) {
			for (let i = startingMonth; i <= endMonth; i++) {
				billingMonthsObj[`${months[i]}-${startDate.format('YY')}`] = `${
					months[i]
				}-${startDate.format('YY')}`;
			}
		} else {
			for (let i = startingMonth; i <= 11; i++) {
				billingMonthsObj[`${months[i]}-${startDate.format('YY')}`] = `${
					months[i]
				}-${startDate.format('YY')}`;
			}
			for (let i = 0; i <= endMonth; i++) {
				billingMonthsObj[`${months[i]}-${endDate.format('YY')}`] = `${
					months[i]
				}-${endDate.format('YY')}`;
			}
		}

		const fromServer = Object.values(billingMonthsObj).join(', ');
		const dates = fromServer.split(', ');
		const startDate1 = dayjs(`01-${dates[0]}`);
		const endDate1 = dayjs(`01-${dates[dates.length - 1]}`);

		return fromServer;
	};

	const onFinish = async (values: any) => {
		const newProducts: { connect: any[]; disconnect: any[] } = {
			connect: [],
			disconnect: [],
		};
		const newMarkets: { connect: any[]; disconnect: any[] } = {
			connect: [],
			disconnect: [],
		};

		report.product.forEach((p) => {
			if (values.product.indexOf(p.id) === -1) {
				newProducts.disconnect.push({ id: p.id });
			}
		});
		report.market.forEach((m) => {
			if (values.market.indexOf(m.id) === -1) {
				newMarkets.disconnect.push({ id: m.id });
			}
		});
		values.product.forEach((vp: any) => {
			if (report.product.map((p) => p.id).indexOf(vp) === -1) {
				newProducts.connect.push({ id: vp });
			}
		});
		values.market.forEach((vm: any) => {
			if (report.market.map((m) => m.id).indexOf(vm) === -1) {
				newMarkets.connect.push({ id: vm });
			}
		});

		// console.log('Report', report, 'Values', values);

		const newCampaign = {
			...values,
			startDate: values.timePeriod![0].format('YYYY-MM-DD'),
			endDate: values.timePeriod![1].format('YYYY-MM-DD'),
			startDate1: values.startDate1,
			endDate1: values.endDate1,
			billingMonth: values.billingMonth
				? convertDateToString(values.billingMonth[0], values.billingMonth[1])
				: null,
			billingDate: values.timeDate
				? values.timeDate.format('YYYY-MM-DD')
				: null,
			agency: { connect: { id: values.agency } },
			salesperson: values.salesperson
				? { connect: { id: values.salesperson } }
				: null,
			accountManager: { connect: { id: values.accountManager } },
			planner: { connect: { id: values.planner } },
			billingCompany: values.billingCompany
				? { connect: { id: values.billingCompany } }
				: null,
			client: { connect: { id: values.client } },
			country: { connect: { id: values.country } },
			market: values.market ? { ...newMarkets } : null,
			investment: values.investment,
			budget: values.budget,

			product: values.product ? { ...newProducts } : null,
		};

		delete newCampaign.timePeriod;

		delete newCampaign.timeDate;

		const { errors } = await updateReportMutation({
			variables: { report: report.id, data: { ...newCampaign } },
		});
		if (errors) {
			console.log(errors);
		}
		await refetch();

		setOpen('none');
	};

	const onFinishFailed = (values: any) => {
		console.log(values);
	};

	const [startDate1, setStartDate1] = useState<Dayjs>();
	const [endDate1, setEndDate1] = useState<Dayjs>();

	const handleConvertDate = (startDate: Dayjs, endDate: Dayjs) => {
		const convertedDate = convertDateToString(startDate, endDate);
		setStartDate1(dayjs(`01-${convertedDate.split(', ')[0]}`));
		setEndDate1(
			dayjs(
				`01-${convertedDate.split(', ')[convertedDate.split(', ').length - 1]}`
			)
		);
	};

	let firstItem;
	let lastItem;

	if (report.billingMonth !== null) {
		const monthForm = report.billingMonth;
		const selectedMonth = monthForm.split(', ');
		firstItem = dayjs(`01-${selectedMonth[0]}`);
		lastItem = dayjs(`01-${selectedMonth[selectedMonth.length - 1]}`);
	} else {
		firstItem = null;
		lastItem = null;
	}

	const [billingDate2, setBillingDate] = useState<Dayjs | null>(null);

	useEffect(() => {
		if (report.billingDate) {
			setBillingDate(dayjs(report.billingDate));
		} else {
			setBillingDate(null);
		}
	}, [report.billingDate]);

	return (
		<Modal
			width={675}
			bodyStyle={{
				paddingTop: '20px',
				paddingRight: '10px',

				maxHeight: 'calc(100vh - 300px)',
				overflow: 'auto',
			}}
			title="Edit Campaign data"
			open={open}
			onOk={handleOk}
			key={report.id}
			destroyOnClose={true}
			onCancel={handleCancel}
			keyboard
			footer={[
				<Button danger onClick={handleCancel}>
					Cancel
				</Button>,
				<Button type="primary" onClick={form.submit}>
					Submit
				</Button>,
			]}>
			<Form
				form={form}
				labelWrap
				className="w-full"
				id="dataInput"
				{...formItemLayout}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				autoComplete="off">
				<Form.Item
					label="Salesperson"
					name="salesperson"
					rules={[{ required: true, message: 'Please select a Salesperson' }]}
					initialValue={report.salesperson?.id}>
					<SalespersonSelect
						placeholder="Salesperson"
						onChange={(value) => form.setFieldsValue({ salesperson: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Country"
					name="country"
					initialValue={report.country.id}
					rules={[{ required: true, message: 'Please select a country' }]}>
					<CountriesSelect
						placeholder="Country"
						onChange={(value) => form.setFieldsValue({ country: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Market"
					name="market"
					initialValue={report.market.map((m) => m.id)}
					rules={[{ required: true, message: 'Please select a market' }]}>
					<MarketSelect
						placeholder="Market"
						onChange={(value) => form.setFieldsValue({ market: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Agency"
					name="agency"
					initialValue={report.agency.id}
					rules={[{ required: true, message: 'Please select an agency' }]}>
					<AgenciesSelect
						placeholder="Agency"
						onChange={(value) => form.setFieldsValue({ agency: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Client"
					name="client"
					initialValue={report.client.id}
					rules={[{ required: true, message: 'Please input a client' }]}>
					<ClientSelectField
						placeholder="Client"
						onChange={(value) => form.setFieldsValue({ client: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Campaign"
					name="campaign"
					initialValue={report.campaign}
					rules={[{ required: true, message: 'Please input a campaign' }]}>
					<Input placeholder="Campaign" />
				</Form.Item>
				<Form.Item
					label="Product"
					name="product"
					initialValue={report.product.map((p) => p.id)}>
					<ProductsSelect
						placeholder="Product"
						onChange={(value) => form.setFieldsValue({ product: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Time period"
					name="timePeriod"
					initialValue={[
						dayjs(report.startDate, 'YYYY-MM-DD'),
						dayjs(report.endDate, 'YYYY-MM-DD'),
					]}
					rules={[
						{ required: true, message: 'Please select a valid date range' },
					]}>
					<DatePicker.RangePicker className="w-full" />
				</Form.Item>
				<Form.Item
					label="Order Month"
					name="billingMonth"
					initialValue={
						report.billingMonth ? [dayjs(firstItem), dayjs(lastItem)] : null
					}>
					<DatePicker.RangePicker
						className="w-full"
						picker="month"
						format={'MMM-YYYY'}
					/>
				</Form.Item>
				<Form.Item
					label="Billing Date"
					name="timeDate"
					initialValue={billingDate2}>
					<DatePicker className="w-full" format={'YYYY-MM-DD'} />
				</Form.Item>
				<Form.Item
					label="Campaign Days"
					name="campaignDays"
					initialValue={report.campaignDays}>
					<InputNumber style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item
					label="Active Days"
					name="activeDays"
					initialValue={report.activeDays}>
					<InputNumber style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item
					label="Investment"
					name="investment"
					initialValue={report.investment}
					rules={[
						{ required: true, message: 'Please input a valid quantity' },
					]}>
					<InputNumber min={0} style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item label="Billing" name="billing" initialValue={report.billing}>
					<InputNumber min={0} style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item
					label="Budget"
					name="budget"
					initialValue={report.budget === null ? 0 : report.budget}
					rules={[
						{ required: true, message: 'Please input a valid quantity' },
					]}>
					<InputNumber min={0} style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item
					label="Exchange Rate"
					name="exchangeRate"
					tooltip="Exchange rate from currency to USD. If currency is already USD, then rate is 1."
					initialValue={report.exchangeRate}
					rules={[
						{ required: true, message: 'Please input a valid quantity' },
					]}>
					<ExchangeRateField
						initialValue={{
							currency: report.currency,
							rate: report.exchangeRate,
						}}
						onChange={(value) =>
							form.setFieldsValue({ ExchangeRateField: value })
						}
					/>
				</Form.Item>
				<Form.Item
					initialValue={report.objectiveCost}
					label="Benchmark Cost"
					name="objectiveCost">
					<InputNumber min={0} style={{ width: '100%' }} />
				</Form.Item>
				<Form.Item
					initialValue={report.orderNumber}
					label="Order number"
					name="orderNumber">
					<Input />
				</Form.Item>
				<Form.Item
					initialValue={report.billNumber}
					label="Bill number"
					name="billNumber">
					<Input />
				</Form.Item>
				<Form.Item
					label="Account Manager"
					name="accountManager"
					initialValue={report.accountManager?.id}
					rules={[
						{ required: true, message: 'Please select an Account Manager' },
					]}>
					<AccountManagerSelect
						placeholder="Account Manager"
						onChange={(value) => form.setFieldsValue({ accountManager: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Planner"
					name="planner"
					initialValue={report.planner?.id}
					rules={[{ required: true, message: 'Please select an Planner' }]}>
					<PlannerSelect
						placeholder="Planner"
						onChange={(value) => form.setFieldsValue({ planner: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Billing Company"
					name="billingCompany"
					initialValue={report.billingCompany?.id}>
					<BillingCompanySelect
						placeholder="Billing Company"
						onChange={(value) => form.setFieldsValue({ billingCompany: value })}
					/>
				</Form.Item>
				<Form.Item
					label="Business Model"
					name="businessModel"
					initialValue={report.businessModel}>
					<BusinessModelSelect
						placeholder="Business Model"
						onChange={(value) => form.setFieldsValue({ businessModel: value })}
					/>
				</Form.Item>
				<Form.Item label="Fee" name="fee" initialValue={report.fee}>
					<InputNumber min={0} style={{ width: '100%' }} />
				</Form.Item>
			</Form>
		</Modal>
	);
};

export default EditCampaignModal;

// import { Report } from '@/interfaces/Report';
// import { UpdateReportMutation, UPDATE_REPORT_MUTATION } from '@/lib/reportsApi';
// import { EditOutlined } from '@ant-design/icons';
// import { useMutation } from '@apollo/client';
// import { Button, DatePicker, Form, Input, InputNumber, Modal, Tooltip } from 'antd';
// import dayjs, { Dayjs } from 'dayjs';
// import { FC, useState, useEffect } from 'react';
// import AccountManagerSelect from '../DataPage/FormFields/AccountManagerSelect';
// import AgenciesSelect from '../DataPage/FormFields/AgencySelect';
// import BillingCompanySelect from '../DataPage/FormFields/BillingCompanySelect';
// import BusinessModelSelect from '../DataPage/FormFields/BusinessModelSelect';
// import ClientSelectField from '../DataPage/FormFields/ClientSelectField';
// import CountriesSelect from '../DataPage/FormFields/CountrySelect';
// import MarketSelect from '../DataPage/FormFields/MarketSelect';
// import ExchangeRateField from '../DataPage/FormFields/ExchangeRateField';
// import PlannerSelect from '../DataPage/FormFields/PlannerSelect';
// import ProductsSelect from '../DataPage/FormFields/ProductSelect';
// import SalespersonSelect from '../DataPage/FormFields/SalespersonSelect';

// type Props = {
// 	report: Report;
// 	refetch: any;
// };

// const formItemLayout = {
// 	labelCol: {
// 		xs: { span: 24 },
// 		sm: { span: 6 },
// 	},
// 	wrapperCol: {
// 		xs: { span: 24 },
// 		sm: { span: 19 },
// 	},
// };

// const EditCampaignModal: FC<Props> = ({ report, refetch }: Props) => {
// 	const [isModalOpen, setIsModalOpen] = useState(false);
// 	const [form] = Form.useForm();

// 	let total = 0;

// 	const [updateReportMutation] = useMutation<any, UpdateReportMutation>(UPDATE_REPORT_MUTATION);

// 	const showModal = () => {
// 		setIsModalOpen(true);
// 		handleConvertDate(dayjs(report.startDate, 'YYYY-MM-DD'), dayjs(report.endDate, 'YYYY-MM-DD'));
// 	};

// 	const handleOk = () => {
// 		setIsModalOpen(false);
// 	};

// 	const handleCancel = () => {
// 		setIsModalOpen(false);
// 	};

// 	if (report.platforms.length > 0) {
// 		total = report.platforms.reduce((prev: any, current: any) => prev + current.investment, 0);
// 	}

// 	const months = [
// 		'Jan',
// 		'Feb',
// 		'Mar',
// 		'Apr',
// 		'May',
// 		'Jun',
// 		'Jul',
// 		'Aug',
// 		'Sep',
// 		'Oct',
// 		'Nov',
// 		'Dec',
// 	];

// 	const convertDateToString = (startDate: Dayjs, endDate: Dayjs): string => {
// 		const startingMonth = startDate.month();
// 		const endMonth = endDate.month();

// 		let billingMonthsObj: { [key: string]: string } = {};

// 		if (endDate.year() === startDate.year()) {
// 			for (let i = startingMonth; i <= endMonth; i++) {
// 				billingMonthsObj[`${months[i]}-${startDate.format('YY')}`] = `${months[i]}-${startDate.format('YY')}`;
// 			}
// 		} else {
// 			for (let i = startingMonth; i <= 11; i++) {
// 				billingMonthsObj[`${months[i]}-${startDate.format('YY')}`] = `${months[i]}-${startDate.format('YY')}`;
// 			}
// 			for (let i = 0; i <= endMonth; i++) {
// 				billingMonthsObj[`${months[i]}-${endDate.format('YY')}`] = `${months[i]}-${endDate.format('YY')}`;
// 			}
// 		}

// 		const fromServer = Object.values(billingMonthsObj).join(', ');
// 		const dates = fromServer.split(', ');
// 		const startDate1 = dayjs(`01-${dates[0]}`);
// 		const endDate1 = dayjs(`01-${dates[dates.length - 1]}`);

// 		return fromServer;
// 	};

// 	const onFinish = async (values: any) => {
// 		const newProducts: { connect: any[]; disconnect: any[] } = { connect: [], disconnect: [] };
// 		const newMarkets: { connect: any[]; disconnect: any[] } = { connect: [], disconnect: [] };

// 		report.product.forEach((p) => {
// 			if (values.product.indexOf(p.id) === -1) {
// 				newProducts.disconnect.push({ id: p.id });
// 			}
// 		});
// 		report.market.forEach((m) => {
// 			if (values.market.indexOf(m.id) === -1) {
// 				newMarkets.disconnect.push({ id: m.id });
// 			}
// 		});
// 		values.product.forEach((vp: any) => {
// 			if (report.product.map((p) => p.id).indexOf(vp) === -1) {
// 				newProducts.connect.push({ id: vp });
// 			}
// 		});
// 		values.market.forEach((vm: any) => {
// 			if (report.market.map((m) => m.id).indexOf(vm) === -1) {
// 				newMarkets.connect.push({ id: vm });
// 			}
// 		});

// 		// console.log('Report', report, 'Values', values);

// 		const newCampaign = {
// 			...values,
// 			startDate: values.timePeriod![0].format('YYYY-MM-DD'),
// 			endDate: values.timePeriod![1].format('YYYY-MM-DD'),
// 			startDate1: values.startDate1,
// 			endDate1: values.endDate1,
// 			billingMonth: values.billingMonth
// 				? convertDateToString(values.billingMonth[0], values.billingMonth[1])
// 				: null,
// 			billingDate: values.timeDate ? values.timeDate.format('YYYY-MM-DD') : null,
// 			agency: { connect: { id: values.agency } },
// 			salesperson: values.salesperson ? { connect: { id: values.salesperson } } : null,
// 			accountManager: { connect: { id: values.accountManager } },
// 			planner: { connect: { id: values.planner } },
// 			billingCompany: values.billingCompany ? { connect: { id: values.billingCompany } } : null,
// 			client: { connect: { id: values.client } },
// 			country: { connect: { id: values.country } },
// 			market: values.market ? { ...newMarkets } : null,
// 			investment: values.investment,
// 			budget: values.budget,

// 			product: values.product ? { ...newProducts } : null,
// 		};

// 		delete newCampaign.timePeriod;

// 		delete newCampaign.timeDate;

// 		const { errors } = await updateReportMutation({
// 			variables: { report: report.id, data: { ...newCampaign } },
// 		});
// 		if (errors) {
// 			console.log(errors);
// 		}
// 		await refetch();

// 		setIsModalOpen(false);

// 	};

// 	const onFinishFailed = (values: any) => {
// 		console.log(values);
// 	};

// 	const [startDate1, setStartDate1] = useState<Dayjs>();
// 	const [endDate1, setEndDate1] = useState<Dayjs>();

// 	const handleConvertDate = (startDate: Dayjs, endDate: Dayjs) => {
// 		const convertedDate = convertDateToString(startDate, endDate);
// 		setStartDate1(dayjs(`01-${convertedDate.split(', ')[0]}`));
// 		setEndDate1(dayjs(`01-${convertedDate.split(', ')[convertedDate.split(', ').length - 1]}`));
// 	};

// 	let firstItem;
// 	let lastItem;

// 	if (report.billingMonth !== null) {
// 		const monthForm = report.billingMonth;
// 		const selectedMonth = monthForm.split(', ');
// 		firstItem = dayjs(`01-${selectedMonth[0]}`);
// 		lastItem = dayjs(`01-${selectedMonth[selectedMonth.length - 1]}`);
// 	} else {
// 		firstItem = null;
// 		lastItem = null;
// 	}

// 	const [billingDate2, setBillingDate] = useState<Dayjs | null>(null);

// 	useEffect(() => {
// 		if (report.billingDate) {
// 			setBillingDate(dayjs(report.billingDate));
// 		} else {
// 			setBillingDate(null);
// 		}
// 	}, [report.billingDate]);

// 	return (
// 		<div>
// 			 <Tooltip title="Edit" placement="top">
// 				<Button icon={<EditOutlined />} type="default" onClick={showModal}></Button>
// 			</Tooltip>
// 			<Modal
// 				width={750}
// 				bodyStyle={{ paddingTop: '20px', maxHeight: 650, overflowY: 'scroll' }}
// 				title="Edit Campaign data"
// 				open={isModalOpen}
// 				onOk={handleOk}
// 				key={report.id}
// 				destroyOnClose={true}
// 				onCancel={handleCancel}
// 				keyboard
// 				footer={[
// 					<Button danger onClick={handleCancel}>
// 						Cancel
// 					</Button>,
// 					<Button type="primary" onClick={form.submit}>
// 						Submit
// 					</Button>,
// 				]}>
// 				<Form
// 					form={form}
// 					labelWrap
// 					className="w-full"
// 					id="dataInput"
// 					{...formItemLayout}
// 					onFinish={onFinish}
// 					onFinishFailed={onFinishFailed}
// 					autoComplete="off">
// 					<Form.Item label="Salesperson" name="salesperson" initialValue={report.salesperson?.id}>
// 						<SalespersonSelect
// 							placeholder="Salesperson"
// 							onChange={(value) => form.setFieldsValue({ salesperson: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Country"
// 						name="country"
// 						initialValue={report.country.id}
// 						rules={[{ required: true, message: 'Please select a country' }]}
// 						>
// 						<CountriesSelect
// 							placeholder="Country"
// 							onChange={(value) => form.setFieldsValue({ country: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Market"
// 						name="market"
// 						initialValue={report.market.map((m) => m.id)}
// 						rules={[{ required: true, message: 'Please select a market' }]}
// 						>
// 						<MarketSelect
// 							placeholder="Market"
// 							onChange={(value) => form.setFieldsValue({ market: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Agency"
// 						name="agency"
// 						initialValue={report.agency.id}
// 						rules={[{ required: true, message: 'Please select an agency' }]}
// 						>
// 						<AgenciesSelect
// 							placeholder="Agency"
// 							onChange={(value) => form.setFieldsValue({ agency: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Client"
// 						name="client"
// 						initialValue={report.client.id}
// 						rules={[{ required: true, message: 'Please input a client' }]}
// 						>
// 						<ClientSelectField
// 							placeholder="Client"
// 							onChange={(value) => form.setFieldsValue({ client: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Campaign"
// 						name="campaign"
// 						initialValue={report.campaign}
// 						rules={[{ required: true, message: 'Please input a campaign' }]}
// 						>
// 						<Input placeholder="Campaign" />
// 					</Form.Item>
// 					<Form.Item label="Product" name="product" initialValue={report.product.map((p) => p.id)}>
// 						<ProductsSelect
// 							placeholder="Product"
// 							onChange={(value) => form.setFieldsValue({ product: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Time period"
// 						name="timePeriod"
// 						initialValue={[
// 							dayjs(report.startDate, 'YYYY-MM-DD'),
// 							dayjs(report.endDate, 'YYYY-MM-DD'),
// 						]}
// 						rules={[{ required: true, message: 'Please select a valid date range' }]}
// 						>
// 						<DatePicker.RangePicker className="w-full" />
// 					</Form.Item>
// 					<Form.Item
// 						label="Order Month"
// 						name="billingMonth"
// 						initialValue={report.billingMonth ? [dayjs(firstItem), dayjs(lastItem)] : null}>
// 						<DatePicker.RangePicker className="w-full" picker="month" format={'MMM-YYYY'} />
// 					</Form.Item>
// 					<Form.Item
// 						label="Billing Date"
// 						name="timeDate"
// 						initialValue={billingDate2}>
// 						<DatePicker className="w-full" format={'YYYY-MM-DD'} />
// 					</Form.Item>
// 					<Form.Item label="Campaign Days" name="campaignDays" initialValue={report.campaignDays}>
// 						<InputNumber style={{ width: '100%' }} />
// 					</Form.Item>
// 					<Form.Item label="Active Days" name="activeDays" initialValue={report.activeDays}>
// 						<InputNumber style={{ width: '100%' }} />
// 					</Form.Item>
// 					<Form.Item
// 						label="Investment"
// 						name="investment"
// 						initialValue={report.investment}
// 						rules={[{ required: true, message: 'Please input a valid quantity' }]}
// 						>
// 						<InputNumber min={0} style={{ width: '100%' }} />
// 					</Form.Item>
// 					<Form.Item label="Billing" name="billing" initialValue={report.billing}>
// 						<InputNumber min={0} style={{ width: '100%' }} />
// 					</Form.Item>
// 					<Form.Item
// 						label="Budget"
// 						name="budget"
// 						initialValue={report.budget === null ? 0 : report.budget}
// 						rules={[{ required: true, message: 'Please input a valid quantity' }]}
// 						>
// 						<InputNumber min={0} style={{ width: '100%' }} />
// 					</Form.Item>
// 					<Form.Item
// 						label="Exchange Rate"
// 						name="exchangeRate"
// 						tooltip="Exchange rate from currency to USD. If currency is already USD, then rate is 1."
// 						initialValue={report.exchangeRate}
// 						rules={[{ required: true, message: 'Please input a valid quantity' }]}
// 						>
// 						<ExchangeRateField
// 							initialValue={{ currency: report.currency, rate: report.exchangeRate }}
// 							onChange={(value) => form.setFieldsValue({ ExchangeRateField: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						initialValue={report.objectiveCost}
// 						label="Benchmark Cost"
// 						name="objectiveCost">
// 						<InputNumber min={0} style={{ width: '100%' }} />
// 					</Form.Item>
// 					<Form.Item initialValue={report.orderNumber} label="Order number" name="orderNumber">
// 						<Input />
// 					</Form.Item>
// 					<Form.Item initialValue={report.billNumber} label="Bill number" name="billNumber">
// 						<Input />
// 					</Form.Item>
// 					<Form.Item
// 						label="Account Manager"
// 						name="accountManager"
// 						initialValue={report.accountManager?.id}
// 						rules={[{ required: true, message: 'Please select an Account Manager' }]}
// 						>
// 						<AccountManagerSelect
// 							placeholder="Account Manager"
// 							onChange={(value) => form.setFieldsValue({ accountManager: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Planner"
// 						name="planner"
// 						initialValue={report.planner?.id}
// 						rules={[{ required: true, message: 'Please select an Planner' }]}
// 						>
// 						<PlannerSelect
// 							placeholder="Planner"
// 							onChange={(value) => form.setFieldsValue({ planner: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Billing Company"
// 						name="billingCompany"
// 						initialValue={report.billingCompany?.id}>
// 						<BillingCompanySelect
// 							placeholder="Billing Company"
// 							onChange={(value) => form.setFieldsValue({ billingCompany: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item
// 						label="Business Model"
// 						name="businessModel"
// 						initialValue={report.businessModel}>
// 						<BusinessModelSelect
// 							placeholder="Business Model"
// 							onChange={(value) => form.setFieldsValue({ businessModel: value })}
// 						/>
// 					</Form.Item>
// 					<Form.Item label="Fee" name="fee" initialValue={report.fee}>
// 						<InputNumber min={0} style={{ width: '100%' }} />
// 					</Form.Item>
// 				</Form>
// 			</Modal>
// 		</div>
// 	);
// };

// export default EditCampaignModal;
