import React from 'react';
import ClientServicesPage from '@/routes/ClientServicesPage';
import Data from '@/routes/DataPage';
import FinancesDataPage from '@/routes/FinancesDataPage';
import FinancesPage from '@/routes/FinancesPage';
import HomePage from '@/routes/HomePage';
import ManageTargets from '@/routes/ManageTargetsPage';
import TargetPage from '@/routes/TargetPage';
import {
	BarChartOutlined,
	ControlOutlined,
	DatabaseOutlined,
	GlobalOutlined,
	HomeOutlined,
	RiseOutlined,
	SettingOutlined,
	TableOutlined,
	PieChartOutlined,
} from '@ant-design/icons';
import ReconciliationPage from '@/routes/ReconciliationPage';
import { PiInvoice } from 'react-icons/pi';

import MediaAnalysisPage from '@/routes/MediaAnalysisPage';

const ADMIN_URL = `${
	process.env.NODE_ENV === 'development'
		? process.env.REACT_APP_BACKEND_URL_DEV
		: process.env.REACT_APP_BACKEND_URL_PROD
}`;

const API_URL = `${ADMIN_URL}/api/graphql`;

type RouteControl = {
	label: string;
	icon: any;
	route: string;
	allowedPermission: string[];
	component?: React.ReactNode;
	isExternal?: boolean;
};

const SITE_ROUTES: RouteControl[] = [
	{
		label: 'Home',
		icon: HomeOutlined,
		route: '/',
		allowedPermission: ['Admin', 'Manager', 'Finances', 'Client Services'],
		component: <HomePage />,
	},
	{
		label: 'Raw Data',
		icon: DatabaseOutlined,
		route: '/data',
		allowedPermission: ['Admin', 'Manager', 'Finances', 'Client Services'],
		component: <Data />,
	},
	{
		label: 'Client Services',
		icon: GlobalOutlined,
		route: '/client-services',
		allowedPermission: ['Admin', 'Manager', 'Client Services'],
		component: <ClientServicesPage />,
	},
	{
		label: 'Billings/Investment Summary',
		icon: BarChartOutlined,
		route: '/finances',
		allowedPermission: ['Admin', 'Finances'],
		component: <FinancesPage />,
	},
	{
		label: 'Pipeline Details',
		icon: TableOutlined,
		route: '/finances-data',
		allowedPermission: ['Admin', 'Finances'],
		component: <FinancesDataPage />,
	},
	{
		label: 'Reconciliation',
		icon: <PiInvoice />,
		route: '/reconciliation-data',
		allowedPermission: ['Admin', 'Finances'],
		component: <ReconciliationPage />,
	},
	{
		label: 'Media Analysis',
		icon: <PieChartOutlined />,
		route: '/media-analysis',
		allowedPermission: ['Admin', 'Manager'],
		component: <MediaAnalysisPage />,
	},

	{
		label: 'Objectives',
		icon: RiseOutlined,
		route: '/objectives',
		allowedPermission: ['Admin', 'Finances'],
		component: <TargetPage />,
	},
	{
		label: 'Manage Objectives',
		icon: SettingOutlined,
		route: '/manage-objectives',
		allowedPermission: ['Admin'],
		component: <ManageTargets />,
	},
	{
		label: 'Admin Panel',
		icon: ControlOutlined,
		route: ADMIN_URL,
		allowedPermission: ['Admin'],
		isExternal: true,
	},
];

const devices = ['Mobile', 'Desktop', 'Tablet', 'Tv Screen'];

const otherVariables = [
	'Viewability',
	'Brand Safety',
	'IAS',
	'MOAT',
	'Impression Tracker',
	'Click Tracker',
	'3rd Party Tags',
];
const businessModels = [
	'Tech fee',
	'Tech cost',
	'Rebate or fix price',
	'Fee over investment',
	'Performance Fee',
];
const buyingTypes = [
	'CPM',
	'CPC',
	'CPV',
	'CPE',
	'CPL',
	'CPA',
	'CPI',
	'CPD',
	'CPR',
];
const secondaryKPIs = [
	'Impressions',
	'Clicks',
	'Interactions',
	'Conversions',
	'Leads',
	'Followers',
	'Likes',
	'Installs',
	'Views',
];

const status = [
	{
		status: 'active',
		color: 'green-500',
	},
	{
		status: 'pending',
		color: 'violet-400',
	},
	{
		status: 'implemented',
		color: 'violet-400',
	},
	{
		status: 'finished',
		color: 'gray-400',
	},
	{
		status: 'paused',
		color: 'red-400',
	},
];

// const reportings = [
// 	'daily',
// 	'weeklyMonday',
// 	'weeklyTuesday',
// 	'weeklyWednesday',
// 	'weeklyThursday',
// 	'weeklyFriday',
// 	'everyTwoWeeks',
// 	'monthly',
// ];

const reportings = [
	'Daily',
	'Weekly Monday',
	'Weekly Tuesday',
	'Weekly Wednesday',
	'Weekly Thursday',
	'Weekly Friday',
	'Every Two Weeks',
	'Monthly',
];

const yesNoPending = ['yes', 'no', 'pending'];

export {
	API_URL,
	ADMIN_URL,
	SITE_ROUTES,
	devices,
	otherVariables,
	businessModels,
	buyingTypes,
	secondaryKPIs,
	status,
	reportings,
	yesNoPending,
};
