import {
	ReportPlatformsData,
	ReportPlatformsVariables,
	REPORT_PLATFORMS_QUERY,
} from '@/lib/reportsApi';
import { capitalize, getPercentageColor, separateCamelCase } from '@/lib/utils';
import { formatMoney } from '@/utils/currency';
import { useQuery } from '@apollo/client';
import { Table, TableColumnsType } from 'antd';
import dayjs from 'dayjs';
import { FC } from 'react';
import AddPlatformModal from './AddPlatformModal';
import EditStatusField from './EditStatusField';
import SpendCell from './SpendCell';
import {
	calculateDateDelivery,
	calculateDailyObjective,
	formatCurrencyAmount,
} from './utils';
import PlatformActions from '@/routes/ClientServicesPage/components/PlatformActions';

type Props = {
	platforms: any;
	startDate: any;
	endDate: any;
	campaignDays: number;
	activeDays: number;
	timePeriod: number;
	budget: number;
	campaignCurrency: string;
	id: string;
	filterSubCampaignColumns: any;
	refetchReports: () => void;
};

enum FixedPosition {
	Left = 'left',
	Right = 'right',
}

const PlatformSubtable: FC<Props> = ({
	refetchReports,
	startDate,
	endDate,
	id,
	campaignDays,
	campaignCurrency,
	activeDays,
	budget,
	filterSubCampaignColumns,
}: Props) => {
	const { loading, data, refetch } = useQuery<
		ReportPlatformsData,
		ReportPlatformsVariables
	>(REPORT_PLATFORMS_QUERY, { variables: { report: id } });

	console.log(
		'Filtros aplicados PLATFORM SUBTABLE: ',
		filterSubCampaignColumns
	);

	const columns: TableColumnsType<any> = [
		// ...(filterSubCampaignColumns.includes('name')
		// 	? [
		// 			{
		// 				title: 'Name',
		// 				dataIndex: 'name',
		// 				key: 'name',
		// 				render: (name: any) => name || 'N/A',
		// 				width: 200,
		// 				// fixed: 'left',
		// 				fixed: FixedPosition.Left,
		// 			},
		// 	  ]
		// 	: []),
		{
			title: 'Name',
			dataIndex: 'name',
			key: 'name',
			render: (name) => name || 'N/A',
			width: 200,
			fixed: 'left',
		},
		// ...(filterSubCampaignColumns.includes('platform')
		// 	? [
		// 			{
		// 				title: 'Platform',
		// 				dataIndex: 'platform',
		// 				key: 'platform',
		// 				render: (platform: any) => (platform ? platform.name : 'N/A'),
		// 				width: 100,
		// 				fixed: FixedPosition.Left,
		// 			},
		// 	  ]
		// 	: []),
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
			render: (platform) => (platform ? platform.name : 'N/A'),
			width: 100,
			fixed: 'left',
		},
		// ...(filterSubCampaignColumns.includes('formats')
		// 	? [
		// 			{
		// 				title: 'Format',
		// 				dataIndex: 'formats',
		// 				key: 'formats',
		// 				width: 100,
		// 				render: (formats: any) =>
		// 					formats.map((format: any) => format.name).join(', '),
		// 				fixed: FixedPosition.Left,
		// 			},
		// 	  ]
		// 	: []),
		{
			title: 'Format',
			dataIndex: 'formats',
			key: 'formats',
			width: 100,
			fixed: 'left',
			render: (formats) => formats.map((format: any) => format.name).join(', '),
		},
		// ...(filterSubCampaignColumns.includes('status')
		// 	? [
		// 			{
		// 				title: 'Status',
		// 				dataIndex: 'status',
		// 				key: 'status',
		// 				width: 150,
		// 				render: (status: any, record: any) => (
		// 					<EditStatusField
		// 						currentStatus={status}
		// 						platformId={record.id}
		// 						refetch={refetch}
		// 					/>
		// 				),
		// 				fixed: FixedPosition.Left,
		// 			},
		// 	  ]
		// 	: []),
		{
			title: 'Status',
			dataIndex: 'status',
			key: 'status',
			width: 150,
			fixed: 'left',
			render: (status, record) => (
				<EditStatusField
					currentStatus={status}
					platformId={record.id}
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Date delivery',
			dataIndex: 'objectiveSpend',
			key: 'status',
			align: 'center',
			render: (platforms: any[], record) => {
				const start = dayjs(startDate);
				const end = dayjs(endDate);

				let content;
				let color;

				const dateDelivery = calculateDateDelivery(
					record,
					campaignDays,
					record.activeDays,
					start
				);

				if (isNaN(dateDelivery) || !isFinite(dateDelivery)) {
					return 'N/A';
				} else {
					if (record.objectiveType === 'absolute') {
						const objective = record.objectiveValue || 0;
						const objectiveSpend = record.objectiveSpend || 0;
						const platformDays = record.platformDays || 0;
						const activeDays = record.activeDays || 0;

						const dailyObjective = objective / platformDays;

						const formula = objectiveSpend / (dailyObjective * activeDays);

						const result = Math.round(formula * 10000) / 100;

						if (dayjs().isBefore(start)) {
							content = 'Not started';
							color = `border border-solid border-green-500 text-green-500`;
						} else if (dayjs().isAfter(end)) {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						} else {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						}
					} else {
						const result = Math.round(dateDelivery * 10000) / 100;

						color = `bg-${getPercentageColor(result)}-500 text-white`;
						content = `${result}%`;

						if (dayjs().isBefore(start)) {
							content = 'Not started';
							color = `border border-solid border-green-500 text-green-500`;
						} else if (dayjs().isAfter(end)) {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						} else {
							color = `bg-${getPercentageColor(result)}-500 text-white`;
							content = `${result}%`;
						}
					}

					return (
						<span
							className={`block  w-full rounded-md px-2 py-1 font-medium ${color}`}>
							{content}
						</span>
					);
				}
			},
			width: 120,
			fixed: 'left',
		},
		{
			title: 'Total Delivery',
			dataIndex: 'objectiveSpend',
			key: 'totalDelivery',
			fixed: 'left',
			width: 120,
			render: (spend, record) => {
				if (record.objectiveValue === null || record.objectiveValue === 0) {
					return 'N/A';
				} else {
					const delivery = spend / record.objectiveValue;
					return `${Math.round(delivery * 100)}%`;
				}
			},
		},
		{
			key: 'startDate',
			dataIndex: 'startDate',
			title: 'Start Date',
			width: 120,
			sorter: (a, b) => dayjs(a.startDate).unix() - dayjs(b.startDate).unix(),
			render: (date) => dayjs(date).format('MMM DD, YYYY'),
		},
		{
			key: 'endDate',
			dataIndex: 'endDate',
			title: 'End Date',
			width: 120,
			render: (date) => dayjs(date).format('MMM DD, YYYY'),
			sorter: (a, b) => dayjs(a.endDate).unix() - dayjs(b.endDate).unix(),
		},
		{
			title: 'Average Daily Objective',
			dataIndex: 'averageDailyObjective',
			key: 'averageDailyObjective',
			width: 180,
			render: (spend, record) => {
				const start = dayjs(startDate);
				const end = dayjs(endDate);
				const campaignDays = end.diff(start, 'days');
				const activeDays = dayjs().diff(start, 'days');
				if (dayjs().isAfter(end)) {
					return 'Finished Campaign';
				}
				const dailyObjective = calculateDailyObjective(
					start,
					record,
					campaignDays,
					activeDays
				);

				if (record.platformDays === null || record.platformDays === 0) {
					return 'N/A';
				} else {
					if (record.objectiveType === 'absolute') {
						return Intl.NumberFormat('en-US', {
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(Math.round(dailyObjective));
					} else {
						const formattedAmount = formatMoney(
							Math.round(dailyObjective * 100) / 100
						);

						const amountWithoutCurrencySymbol = formattedAmount
							.replace(/^\$\s*/, '')
							.trim();

						return `${campaignCurrency} ${amountWithoutCurrencySymbol}`;
					}
				}
			},
		},
		{
			title: 'Daily Objective',
			dataIndex: 'objectiveSpend',
			key: 'dailyObjective',
			width: 120,
			render: (value, record) => {
				const objective = record.objectiveValue || 0;
				const objectiveSpend = record.objectiveSpend || 0;
				const platformDays = record.platformDays || 0;
				const activeDays = record.activeDays || 0;

				if (
					platformDays === 0 ||
					activeDays === 0 ||
					isNaN(objective) ||
					isNaN(objectiveSpend) ||
					isNaN(platformDays) ||
					isNaN(activeDays)
				) {
					return 'N/A';
				}

				const averageDailyObjective =
					(objective - objectiveSpend) / (platformDays - activeDays);
				if (isNaN(averageDailyObjective) || !isFinite(averageDailyObjective)) {
					return 'N/A';
				}

				return averageDailyObjective.toLocaleString('en-US', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				});
			},
		},
		{
			title: 'Days',
			dataIndex: 'platformDays',
			key: 'platformDays',
			width: 90,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Days updated."
					updateField="platformDays"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Active days',
			dataIndex: 'activeDays',
			key: 'activeDays',
			width: 105,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Active days updated."
					updateField="activeDays"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Objective',
			dataIndex: 'objectiveValue',
			key: 'objective',
			width: 105,
			render: (objective, record) => {
				if (!objective) return `${campaignCurrency} 0`;

				const formattedObjective = new Intl.NumberFormat().format(objective);

				const value =
					record.objectiveType === 'budget'
						? `${campaignCurrency} ${formattedObjective}`
						: `${formattedObjective}`;

				return <span>{value}</span>;
			},
		},
		{
			title: 'Objective Spend',
			dataIndex: 'objectiveSpend',
			key: 'objectiveSpend',
			width: 130,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Objective Spend updated."
					refetch={refetch}
					updateField="objectiveSpend"
					prefix={record.objectiveType === 'budget' ? campaignCurrency : ''}
				/>
			),
		},
		{
			title: 'Spend',
			dataIndex: 'objectiveSpend',
			key: 'status',
			width: 120,
			render: (value, record) => {
				const rate = record.rate || 1;
				let formattedAmount;

				if (record.objectiveType === 'absolute') {
					if (record.buyingType === 'CPM' || record.buyingType === 'CPR') {
						formattedAmount = (value * rate) / 1000 || '0,00';
					} else {
						formattedAmount = value * rate || '0,00';
					}
				}

				if (record.objectiveType === 'budget') {
					const amount = value || '0,00';
					formattedAmount = `${campaignCurrency} ${amount}`;
				}

				return formattedAmount;
			},
		},

		{
			title: 'Rate',
			dataIndex: 'rate',
			key: 'rate',
			width: 120,
			render: (rate, record) => {
				if (!rate || isNaN(rate)) {
					return 'N/A for objective';
				}

				if (record.objectiveType === 'absolute') {
					return `${campaignCurrency} ${rate}`;
				} else {
					return 'N/A for objective';
				}
			},
		},
		{
			title: 'Effective Cost',
			dataIndex: 'secondaryKPIEffectiveCost',
			key: 'secondaryKPIEffectiveCost',
			width: 130,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Secondary KPI Effective Cost updated"
					updateField="secondaryKPIEffectiveCost"
					refetch={refetch}
					prefix={campaignCurrency}
				/>
			),
		},
		{
			title: 'Objective Optimization',
			dataIndex: 'rate',
			width: 180,
			key: 'objectiveOptimization',
			render: (rate, record) => {
				if (!rate || isNaN(rate) || isNaN(record.objectiveEffectiveCost)) {
					return 'N/A for objective';
				}

				if (record.objectiveType === 'absolute') {
					return `${
						Math.round(
							((rate - record.secondaryKPIEffectiveCost) / rate) * 10000
						) / 100
					}%`;
				} else {
					return 'N/A for objective';
				}
			},
		},
		{
			title: 'Benchmark Cost',
			dataIndex: 'objectiveEffectiveCost',
			key: 'objectiveEffectiveCost',
			width: 130,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Objective Effective Cost updated."
					updateField="objectiveEffectiveCost"
					refetch={refetch}
					prefix={campaignCurrency}
				/>
			),
		},
		{
			title: 'Benchmark Optimization',
			dataIndex: 'objectiveType',
			key: 'secondaryKPIOptimization',
			width: 200,
			render: (value, record) => {
				let objectiveCost = record.objectiveEffectiveCost;
				const effectiveCost = record.secondaryKPIEffectiveCost;

				if (record.objectiveType === 'absolute') {
					const optimization =
						((objectiveCost - effectiveCost) / objectiveCost) * 100;

					if (!isFinite(optimization)) {
						return 'N/A';
					}
					return `${Math.round(optimization * 100) / 100}%`;
				} else {
					const optimization =
						((objectiveCost - effectiveCost) / objectiveCost) * 100;

					if (!isFinite(optimization)) {
						return 'N/A';
					}
					return `${Math.round(optimization * 100) / 100}%`;
				}
			},
		},
		{
			title: 'Secondary KPI',
			dataIndex: 'secondaryKPIValue',
			key: 'secondaryKPI',
			width: 120,
			render: (secondaryKPI, record) => {
				if (secondaryKPI === null || secondaryKPI === undefined) {
					return 'N/A';
				}
				return `${secondaryKPI.toLocaleString('en-EN', {
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				})} (${record.secondaryKPIType})`;
			},
		},
		{
			title: '2nd KPI Spend',
			dataIndex: 'secondaryKPISpend',
			key: 'secondaryKPISpend',
			width: 120,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Secondary KPI Spend updated."
					updateField="secondaryKPISpend"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Delivery 2nd KPI',
			dataIndex: 'secondaryKPISpend',
			key: 'secondaryKPITotalDelivery',
			width: 160,
			render: (spend, record) =>
				spend
					? `${Math.round((spend / record.secondaryKPIValue) * 100)}%`
					: 'N/A',
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			width: 200,
			render: (businessModels) =>
				businessModels.map((model: string) => model).join(', '),
		},
		{
			title: 'Fee',
			dataIndex: 'fee',
			key: 'fee',
			width: 80,
		},
		{
			title: 'Buying Type',
			dataIndex: 'buyingType',
			width: 120,
			key: 'buyingType',
			render: (value, record) => {
				if (record.objectiveType === 'absolute') {
					return value;
				} else {
					return '';
				}
			},
		},

		{
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
			width: 105,
			render: (investment) => {
				const formattedAmount = formatMoney(
					investment,
					'en-US',
					campaignCurrency
				);
				return formattedAmount;
			},
		},
		{
			title: 'Budget',
			dataIndex: 'budget',
			key: 'budget',
			width: 105,
			render: (budget) => {
				const formattedAmount = formatMoney(budget, 'en-US', campaignCurrency);
				return formattedAmount;
			},
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			width: 130,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="Cost updated."
					updateField="cost"
					refetch={refetch}
					prefix={campaignCurrency}
				/>
			),
		},
		{
			title: 'Devices',
			dataIndex: 'devices',
			key: 'devices',
			width: 200,
			render: (devices) => devices.join(', '),
		},
		{
			title: 'Other Variables',
			dataIndex: 'otherVariables',
			key: 'otherVariables',
			width: 200,
			render: (variables) => variables.join(', '),
		},
		{
			title: 'VTR%',
			dataIndex: 'vtr',
			key: 'vtr',
			width: 70,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="VTR updated."
					updateField="vtr"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'CTR%',
			dataIndex: 'ctr',
			key: 'ctr',
			width: 70,
			render: (spend, record) => (
				<SpendCell
					spend={spend}
					record={record}
					successMessage="CTR updated."
					updateField="ctr"
					refetch={refetch}
				/>
			),
		},
		{
			title: 'Reporting',
			dataIndex: 'reporting',
			key: 'reporting',
			width: 120,
			render: (value) => (value ? separateCamelCase(value) : 'N/A'),
		},
		{
			title: 'Final Report',
			dataIndex: 'finalReport',
			key: 'finalReport',
			width: 100,
			render: (value) => (value ? capitalize(value) : 'N/A'),
		},
		{
			title: 'IO or PO',
			dataIndex: 'ioOrPo',
			key: 'ioOrPo',
			width: 90,
			render: (value) => (value ? capitalize(value) : 'N/A'),
		},
		{
			title: 'Screenshots',
			dataIndex: 'screenshots',
			key: 'screenshots',
			width: 110,
			render: (value) => (value ? capitalize(value) : 'N/A'),
		},
		{
			title: 'Reconciliation',
			dataIndex: 'reconciliation',
			key: 'reconciliation',
			width: 120,
			render: (value) => (value ? capitalize(value) : 'N/A'),
		},
		{
			title: 'Actions',
			dataIndex: 'notes',
			width: 70,
			fixed: 'right',
			align: 'center',
			key: 'notes',
			render: (value, record) => (
				<PlatformActions
					platform={record}
					refetch={refetch}
					reportId={id}
					refetchReports={refetchReports}
				/>
			),
		},
	];

	return (
		<div>
			<AddPlatformModal
				report={{ id, startDate, endDate }}
				refetch={refetch}
				refetchReports={refetchReports}
			/>

			{data?.report.platforms && data?.report.platforms.length > 0 && (
				<Table
					rowKey={(record) => record.id}
					columns={columns}
					className="platform-subtable bg-gray-50"
					size="small"
					bordered
					loading={loading}
					rowClassName="bg-white opacity-1"
					dataSource={data?.report.platforms}
					pagination={false}
					scroll={{ x: 4400 }}
				/>
			)}
		</div>
	);
};

export default PlatformSubtable;
