import {
	Checkbox,
	Form,
	Input,
	InputNumber,
	Table,
	TableColumnsType,
	Tooltip,
	Typography,
} from 'antd';
import { FinancialRow } from './ReconciliationPage';
import TextArea from 'antd/es/input/TextArea';
import { useMutation } from '@apollo/client';
import { UPDATE_FINANCIAL_ROW } from '@/lib/ReconciliationApi';
import dayjs from 'dayjs';
import ReconciliationTableSummary from '@/routes/ReconciliationPage/ReconciliationTableSummary';
import { Report } from '@/interfaces/Report';
import {
	UPDATE_PLATFORM_MUTATION,
	UPDATE_REPORT_MUTATION,
	UpdateReportMutation,
} from '@/lib/reportsApi';
import { getParsedValue } from '@/routes/ReconciliationPage/utils';

type Props = {
	rows: FinancialRow[];
	refetch: () => Promise<void>;
	loading: boolean;
};

const ReconciliationTable = ({ rows, refetch }: Props) => {
	const [form] = Form.useForm();

	const [updateRowMutation, { loading: updateRowLoading }] = useMutation<
		FinancialRow,
		{ rowID: string; data: Partial<FinancialRow> }
	>(UPDATE_FINANCIAL_ROW);

	const [updateReportMutation, { loading: updateReportLoading }] = useMutation<
		Report,
		UpdateReportMutation
	>(UPDATE_REPORT_MUTATION);

	const [updateReportPlatformMutation, { loading: updatePlatformLoading }] =
		useMutation(UPDATE_PLATFORM_MUTATION);

	const tableColumns: TableColumnsType<FinancialRow> = [
		{
			title: 'Agency',
			dataIndex: 'agency',
			key: 'agency',
			fixed: true,
			width: 200,
		},
		{
			title: 'Client',
			dataIndex: 'client',
			key: 'client',
			fixed: true,
			width: 150,
		},
		{
			title: 'Campaign',
			dataIndex: 'campaign',
			key: 'campaign',
			fixed: true,
			width: 150,
		},
		{
			title: 'Sub Campaign',
			dataIndex: 'platformName',
			key: 'platformName',
			fixed: true,
			width: 150,
		},
		{
			title: 'Date period',
			dataIndex: 'startDate',
			fixed: true,
			key: 'startDate',
			render: (_, { startDate, endDate }) => (
				<>
					{dayjs(startDate).format('DD/MM')} - {dayjs(endDate).format('DD/MM')}
				</>
			),
		},
		{
			title: 'Order Number',
			dataIndex: 'orderNumber',
			key: 'orderNumber',
			render: (val, record) => (
				<Form.Item name={`orderNumber-${record.reportId}`} noStyle>
					<Input
						defaultValue={val}
						disabled={updateReportLoading}
						onBlur={(element) => {
							if (element.target.value) {
								updateReport(
									record.reportId,
									'orderNumber',
									element.target.value
								);
							}
						}}
					/>
				</Form.Item>
			),
		},

		{
			title: 'Product',
			dataIndex: 'product',
			key: 'product',
			width: 200,
			render: (products) => (
				<div className="flex items-center">
					<Tooltip title={products.join(', ')}>
						<Typography.Paragraph
							style={{ marginBottom: 0 }}
							ellipsis={{ rows: 1 }}>
							{products.join(', ')}
						</Typography.Paragraph>
					</Tooltip>
				</div>
			),
		},
		{
			title: 'Platform',
			dataIndex: 'platform',
			key: 'platform',
		},
		{
			title: 'Formats',
			dataIndex: 'formats',
			key: 'formats',
			render: (formats) => formats.join(', '),
		},
		{
			title: 'Business Model',
			dataIndex: 'businessModel',
			key: 'businessModel',
			width: 150,
			render: (businessModels) => businessModels.join(', '),
		},
		{
			title: 'Buying Type & Rate',
			dataIndex: 'buyingType',
			key: 'buyingType',
			width: 150,
			render: (buyingType, { rate }) =>
				buyingType
					? `${buyingType ? `${buyingType}` : ''} ${rate ? `($${rate})` : ''}`
					: 'N/A',
		},
		{
			title: 'On TVL',
			dataIndex: 'onTvl',
			key: 'onTvl',
			width: 75,
			align: 'center',
			render: (val, record) => (
				<Form.Item
					name={`onTvl-${record.platformId}`}
					noStyle
					valuePropName="checked"
					initialValue={val}>
					<Checkbox
						style={{ zIndex: 1 }}
						disabled={updatePlatformLoading}
						onChange={(event) =>
							updateReportPlatform(
								record.platformId,
								'onTvl',
								event.target.checked
							)
						}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Investment',
			dataIndex: 'investment',
			key: 'investment',
			render: (val, record) => (
				<Form.Item
					name={`investment-${record.platformId}`}
					noStyle
					initialValue={val}>
					<InputNumber
						disabled={updatePlatformLoading}
						onBlur={(element) => {
							const v = element.target.value;

							updateReportPlatform(
								record.platformId,
								'investment',
								getParsedValue(v)
							);
						}}
						style={{ width: '100%' }}
						prefix={record.currency}
					/>
				</Form.Item>
			),
		},

		{
			title: 'Monthly Billing',
			dataIndex: 'monthlyBilling',
			key: 'monthlyBilling',
			width: 200,
			render: (val, record) => (
				<Form.Item
					name={`monthlyBilling-${record.id}`}
					style={{ marginBottom: 0 }}
					initialValue={val}
					rules={[
						{ required: true, message: '' },
						{ type: 'number', min: 0, message: '' },
						{
							type: 'number',
							max: record.investment,
							warningOnly: true,
							message: 'Value higher than investment',
						},
					]}>
					<InputNumber
						disabled={updateRowLoading}
						onBlur={(element) => {
							let v = element.target.value;

							updateFinancialRow(
								record.id,
								'monthlyBilling',
								getParsedValue(v)
							);
						}}
						style={{ width: '100%' }}
						prefix={record.currency}
					/>
				</Form.Item>
			),
		},
		{
			title: 'Cost',
			dataIndex: 'cost',
			key: 'cost',
			width: 200,

			render: (val, record) => (
				<Form.Item
					name={`cost-${record.id}`}
					initialValue={val}
					style={{ marginBottom: 0 }}
					rules={[
						{ required: true, message: '' },
						{ type: 'number', min: 0, message: '' },
						{
							type: 'number',
							max: record.investment,
							warningOnly: true,
							message: 'Value higher than investment',
						},
					]}>
					<InputNumber
						disabled={updateRowLoading}
						onBlur={(element) => {
							let v = element.target.value;

							updateFinancialRow(record.id, 'cost', getParsedValue(v));
						}}
						style={{ width: '100%' }}
						prefix="$"
					/>
				</Form.Item>
			),
		},
		{
			title: 'Comment',
			dataIndex: 'comment',
			key: 'comment',
			width: 300,
			render: (val, record) => (
				<Form.Item
					name={`comment-${record.id}`}
					initialValue={val}
					style={{ marginBottom: 0 }}>
					<TextArea
						disabled={updateRowLoading}
						onBlur={(element) => {
							updateFinancialRow(record.id, 'comment', element.target.value);
						}}
						className="mb-0 w-full"
						autoSize={{ minRows: 1, maxRows: 2 }}
						style={{ resize: 'vertical' }}
					/>
				</Form.Item>
			),
		},
	];

	const updateReport = async (reportId: string, field: string, value: any) => {
		await updateReportMutation({
			variables: {
				report: reportId,
				data: { [field]: value },
			},
		});
		await refetch();
	};

	const updateReportPlatform = async (
		platformId: string,
		field: string,
		value: any
	) => {
		await updateReportPlatformMutation({
			variables: {
				id: platformId,
				data: { [field]: value },
			},
		});
		await refetch();
	};

	const updateFinancialRow = async (
		rowId: string,
		field: string,
		value: any
	) => {
		await updateRowMutation({
			variables: {
				rowID: rowId,
				data: { [field]: value },
			},
		});
		await refetch();
	};

	return (
		<div className="rounded-lg border border-gray-200 bg-white p-1 shadow">
			<Form form={form}>
				<Table
					size="small"
					loading={updateRowLoading}
					bordered
					pagination={false}
					scroll={{ x: 2500 }}
					columns={tableColumns}
					rowKey={(record) => record.id}
					summary={() => (
						<Table.Summary fixed="bottom">
							<ReconciliationTableSummary
								totals={rows.reduce(
									(prev, curr) => {
										return {
											totalCost: prev.totalCost + curr.cost,
											totalInvestment:
												prev.totalInvestment +
												curr.investment * curr.exchangeRate,
											totalMonthlyBilling:
												prev.totalMonthlyBilling +
												curr.monthlyBilling * curr.exchangeRate,
										};
									},
									{ totalCost: 0, totalInvestment: 0, totalMonthlyBilling: 0 }
								)}
							/>
						</Table.Summary>
					)}
					dataSource={rows}></Table>
			</Form>
		</div>
	);
};

export default ReconciliationTable;
